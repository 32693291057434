import { render, staticRenderFns } from "./MobileContentsDetail.vue?vue&type=template&id=0d5c14a8&"
import script from "./MobileContentsDetail.vue?vue&type=script&lang=js&"
export * from "./MobileContentsDetail.vue?vue&type=script&lang=js&"
import style0 from "./MobileContentsDetail.vue?vue&type=style&index=0&id=0d5c14a8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports